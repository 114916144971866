/* [JobRequestForm.css START] */


/****************************************
 * JobRequestForm.js START
 ****************************************/
 .job-request-form {
  width: 100%;
  /* height: 90%; */
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.job-request-form h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
}

.job-request-form label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  color: #555;
}

.job-request-form input[type="text"],
.job-request-form input[type="email"],
.job-request-form input[type="tel"],
.job-request-form input[type="date"],
.job-request-form textarea,
.job-request-form select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.job-request-form textarea {
  resize: vertical;
}

.form-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.form-navigation button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
}

.form-navigation button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.form-navigation button:hover:not(:disabled) {
  background-color: #0056b3;
}

/****************************************
 * JobRequestForm.js Input Focus START
 ****************************************/
 .job-request-form input:focus,
 .job-request-form textarea:focus,
 .job-request-form select:focus {
   outline: 1px solid var(--mmg-yellow);
   border-color: var(--mmg-yellow);
 }
 /****************************************
  * JobRequestForm.js Input Focus END
  ****************************************/

/****************************************
 * JobRequestForm.js END
 ****************************************/

/****************************************
 * JobRequestForm-Information.js START
 ****************************************/
/* Add styles for the Information section here */

.job-request-form-section {
  margin-bottom: 20px;
}

/****************************************
 * JobRequestForm-Information.js END
 ****************************************/


 /****************************************
 * JobRequestForm-ReportDetails.js START
 ****************************************/
.address-line3-postcode {
  display: flex;
  gap: 10px;
}

.address-line3-postcode label {
  flex: 1;
}
/****************************************
 * JobRequestForm-ReportDetails.js END
 ****************************************/

/****************************************
 * JobRequestForm-JobDetails.js START
 ****************************************/
/* Add styles for the Job Details section here */

/****************************************
 * JobRequestForm-JobDetails.js END
 ****************************************/


/****************************************
 * JobRequestForm-IssueMedia.js START
 ****************************************/
/* Add styles for the Issue Media section here */

/****************************************
 * JobRequestForm-IssueMedia.js END
 ****************************************/

.job-request-header {
  display: block;
  width: 100%;
  color: #fff;
  background-color: #95a098;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.job-request-header h2 {
  color: #fff;
}

.job-request-header p {
  margin: 10px;
}

.job-request-return {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 20px;
}

/****************************************
 * Submission Status Message
 ****************************************/
.submission-status {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #007bff; /* Blue for progress */
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

/* Error styling */
.submission-status.error {
  color: #dc3545; /* Red for errors */
}

/* Success styling */
.submission-status.success {
  color: #28a745; /* Green for success */
}

/* Fade-in effect */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* [JobRequestForm.css END] */