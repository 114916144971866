/* [JobRequestFormJobDetails.css START] */

/****************************************
 * Trade Selection Panel
 ****************************************/
 .job-request-form {
  padding: 20px;
}

.trades-selection-panel {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.trade-button {
  padding: 10px;
  background-color: #f0f0f0;
  color: var(--mmg-txtColor-contrast);
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.trade-button.selected {
  background-color: #007bff;
  color: white;
}

/****************************************
 * Outer Container (Full Row)
 ****************************************/
.trade-container {
  background-color: #f1f4f0; /* Light Orange */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row; /* Ensures correct alignment */
  justify-content: flex-end;
  align-items: stretch; /* Forces children to match height */
  height: 500px; /* Temporary fixed height for debugging */
}

/****************************************
 * Trade Row (Title + Calendar)
 ****************************************/
 .trade-row {
  display: flex;
  width: 100%;
  flex-grow: 1; /* Allows row to take full height of trade-container */
  height: 100%; /* Ensures it takes full available height */
  align-items: stretch; /* Ensures children stretch to full height */
}

/* Trade Title (2/3 width) */
.trade-header {
  background-color: #fff; /* Light Blue */
  padding: 10px;
  width: 66.66%;
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  display: flex;
  flex-direction: column; /* Ensures elements stack vertically */
  align-items: flex-start; /* Aligns content to the top */
}

/* Wrapper to Ensure Proper Placement */
.calendar-wrapper {
  width: 33.33%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

/* Calendar Container (Right Side, Full Height) */
.calendar-container {
  width: 33.33%; 
  background-color: #f1f4f0; /* Light Green */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: 100%;
}

/* Calendar Styling Adjustments */
.calendar-container .react-calendar {
  width: 100%;
  max-width: 250px;
  min-height: 100%; /* Forces the full calendar to show */
  font-size: 0.8em;
  display: flex;
  flex-direction: column; /* Ensures correct layout */
}


.calendar-container .react-calendar__month-view {
  flex-grow: 1; /* Forces the month view (dates) to appear */
  min-height: 200px; /* Prevents collapse */
}


.calendar-container .react-calendar__tile {
  height: 25px !important; /* Reduce individual tile height */
}

/****************************************
 * Text Area Row (2/3 Width)
 ****************************************/
 .trade-detail-row {
  display: flex;
  width: 66.66%;
  gap: 10px;
  background-color: pink; /* Light Yellow */
  padding: 10px;
}

/* Textareas */
.trade-detail-row textarea {
  width: 50%;
  height: 80px; /* Default height */
  padding: 8px;
  font-size: 1em;
  resize: vertical;
}

.trade-title {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  background-color: #f1f4f0;
  width: 100%; /* Ensures it spans the full width of the parent */
  align-self: flex-start; /* Aligns it to the top of the parent */
}

.trade-info {
  width: 100%; /* Ensures it spans the full width */
  background-color: #f1f4f0; /* Contrasting blue */
  height: 120px; /* Temporary height for visibility */
  margin-top: 5px;
  display: flex; 
  justify-content: space-between;
  align-items: stretch;
}

.trade-textareas {
  display: flex;
  gap: 10px;
  width: 100%; /* Ensures both textareas take full space */
  height: 100%;
}

.trade-textareas textarea {
  flex: 1; /* Ensures equal width */
  height: 100%;
  padding: 8px;
  font-size: 1em;
  resize: vertical;
}

.trade-add-photos {
  width: 100%; /* Ensures it spans the full width of .trade-header */
  background-color: #f1f4f0; /* Gold color for easy visibility */
  height: 50px; /* Temporary height for debugging */
  margin-top: 25px; /* Space below trade-info */
}

.trade-uploaded-photos {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  background-color: #f1f4f0; /* Light Lavender for visibility */
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

/* Individual Uploaded Photo Styling */
.uploaded-photo {
  width: 80px; /* Thumbnail size */
  height: 80px;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: transform 0.2s ease-in-out;
}

.uploaded-photo:hover {
  transform: scale(1.1);
}

.calendarPrompt {
  /* display:flexbox; */
  width: 100%;
  font-size: 14px;
  color: #666;
  text-align: center;
  margin-top: 8px;  
  background-color: green;
}


/* [JobRequestFormJobDetails.css END] */
