/****************************************
 * JOB DETAIL CONTAINER
 ****************************************/
 .jobDetailContainer {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/****************************************
 * FORM ELEMENTS
 ****************************************/
 .jobDetailRow {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 15px;
  align-items: center;
}

/* Forces the textareas to align properly below labels */
.textareaRow {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 15px;
}


.formGroup {
  display: flex;
  flex-direction: column;
  flex: 1;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input,
textarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

textarea {
  resize: none;
}

input[readonly],
textarea[readonly] {
  background-color: #f3f3f3;
  color: #555;
}

/****************************************
 * TABS SECTION
 ****************************************/
.tabs {
  margin-top: 30px;
  display: flex;
  gap: 10px;
  border-bottom: 2px solid #ddd;
}

.tabButton {
  padding: 10px 20px;
  background-color: #f3f3f3;
  border: none;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tabButton:hover {
  background-color: #eaeaea;
}

.tabButton.active {
  background-color: #ffffff;
  border-bottom: 2px solid #ffffff;
  color: #007bff;
}

/****************************************
 * TAB CONTENT
 ****************************************/
/* General content area styling */
.propertyImagesSection,
.propertyLocationSection {
  margin-top: 20px;
}

/****************************************
 * PROPERTY IMAGES SECTION
 ****************************************/
 .imageGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  margin-top: 20px;
}

.imageContainer {
  overflow: hidden;
  border-radius: 8px;
}

.propertyImage {
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer; 
  transition: opacity 0.2s ease-in-out;
}

.propertyImage:hover {
  opacity: 0.8; /* Subtle hover effect */
}

/****************************************
 * LOCATION MAP SECTION
 ****************************************/
.propertyLocationSection {
  margin-top: 20px;
}

.map {
  width: 100%;
  height: 300px;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


/****************************************
 * Floorplan Section 
 ****************************************/

* 
.floorplanSection {
  margin-top: 20px;
}

.floorplanImage {
  width: 100%;
  max-width: 600px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: block;
  margin: 0 auto;
}


/****************************************
 * Relationship Tab Section 
 ****************************************/

.relationshipSection {
  padding: 20px;
}

.workList {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
}

.workItem {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.workItem p {
  margin: 5px 0;
}

h3 {
  margin-top: 20px;
}
