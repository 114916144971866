/* [Notifications.module.css START] */
.notificationsContainer {
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 400px;
  margin: 0 auto;
}

.notificationsTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
}

.notificationsList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.notificationItem {
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 8px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notificationItem:last-child {
  margin-bottom: 0;
}

.notificationMessage {
  font-size: 14px;
  color: #333;
}

.notificationTime {
  font-size: 12px;
  color: #999;
}


/****************************************
 * Button Styling for Mark as Unread
 ****************************************/
 .markAsUnreadButton {
  background-color: transparent;
  border: 1px solid #d9534f; /* Soft red border */
  color: #d9534f; /* Soft red text */
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.markAsUnreadButton:hover {
  background-color: #d9534f; /* Soft red background */
  color: white;
}

.markAsReadButton {
  background-color: transparent;
  border: 1px solid #5cb85c; /* Soft green border */
  color: #5cb85c; /* Soft green text */
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.markAsReadButton:hover {
  background-color: #5cb85c; /* Soft green background */
  color: white;
}

.markAsUnreadButton:focus {
  outline: none;
  box-shadow: 0 0 3px rgba(217, 83, 79, 0.6);
}

.deleteButton {
  background-color: transparent;
  border: 1px solid rgb(76, 76, 215); /* Soft green border */
  color: rgb(76, 76, 215); /* Soft green text */
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease, color 0.2s ease;  
}

.deleteButton:hover {
  background-color: rgb(76, 76, 215); /* Soft green background */
  color: white;
}

/* [Notifications.module.css END] */
