/* Reduce overall calendar size */
.availability-calendar {
  width: 100%;
  height: auto;
  min-height: 200px; /* Adjusted for better scaling */
  overflow: hidden;
}

/* Reduce size of individual day cells */
.fc-daygrid-day {
  height: 18px !important; /* Shrink individual day boxes */
}

/* Reduce font size */
.fc {
  font-size: 0.75em !important; /* Scale down text proportionally */
}

.fc-toolbar-title {
  font-size: 1.3em !important; /* Reduce header text */
}

/* Ensure Clicking on "Available" Events Works */
.fc-event {
  pointer-events: none !important; /* Allows click passthrough */
}

/* Style the day of the week headers */
.fc-col-header-cell {
  background-color: #95a098 !important; /* Site's color */
  color: white !important; /* White text for readability */
  font-weight: bold;
}

