/****************************************
 * SettingsUsers.css START
 ****************************************/

 .users-table h2 {
  color: var(--mmg-txtColor);
}

.users-heading {
  margin: 20px 0;
}

.users-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.users-topbar h2 {
  margin: 0;
}

.users-topbar button {
  padding: 6px 12px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #f7f7f7;
  cursor: pointer;
}

.users-topbar button:hover {
  background: #e0e0e0;
}



/* ------- */

.users-topbar-elevated {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  border: 1px solid #e2e2e2;
}

.users-heading-group h2 {
  margin: 0;
  font-size: 1.5rem;
}

.user-count {
  display: block;
  font-size: 0.9rem;
  color: #777;
  margin-top: 4px;
}

.toggle-disabled-button {
  padding: 8px 16px;
  font-size: 0.95rem;
  border: none;
  border-radius: 6px;
  background: linear-gradient(to right, #3b82f6, #60a5fa);
  color: white;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
  transition: background 0.3s ease;
}

.toggle-disabled-button:hover {
  background: linear-gradient(to right, #2563eb, #3b82f6);
}



/****************************************
 * SettingsUsers.css END
 ****************************************/
