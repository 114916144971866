/* Container */
.container {
  max-width: 90%;
  margin: auto;
  padding: 24px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

/* Header */
h2 {
  text-align:start;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

/* Form */
.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Fieldset Styling */
.profileEditFieldset {
  border: none;
  padding: 16px;
  background: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 10px;
  border: 1px solid rgba(17, 43, 26, 0.1);
}

legend {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
}

/* Input Fields */
.row {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 10px;
}

.row > div {
  flex: 1;
  min-width: 280px;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 6px;
  color: #444;
}

.profileInput {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  background-color: #f3f3f3;
}

/* Checkbox */
.termsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.termsContainer input {
  width: 20px;
  height: 20px;
}

.termsContainer label {
  font-size: 14px;
}

.termsContainer button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
}

.termsContainer button:hover {
  text-decoration: underline;
}

/* Submit Button */
.profileEditButton {
  width:fit-content;
  padding: 12px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease-in-out;
}

.profileEditButton:hover {
  background: #0056b3;
}

.profileEditsubmitContainer {
  display: flex;
  align-items: center; /* Align items vertically */
  justify-content: flex-end; /* Keep button on the right */
  gap: 20px; /* Space between button & message */
}

.submitMessage {
  flex-grow: 1; /* Takes up space when visible, prevents button from shifting */
  text-align: left;
  color: green;
  font-weight: bold;
  white-space: nowrap; /* Prevents breaking to new line */
}
.notificationPreferences {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkboxRow {
  display: flex;
  align-items: center;
  gap: 8px;
}

.profileEditLabel {
  display: block; /* Ensures label takes full width */
  font-size: 16px;
  color: #333; /* Adjust text color */
  margin-top: 5px;
  margin-bottom: 5px;
  line-height: 1.5;
}

.profileEditCheckboxRow {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap; 
}

.profileEditInfo {
  font-weight: lighter;
}

.profileEditSubheadingRow {
  display: flex;
  justify-content: space-between; /* Keeps heading on the left, button on the right */
  align-items: center; /* Aligns items neatly */
  padding: 10px 15px;
  background-color: rgba(17, 43, 26, 0.1); /* Soft greenish tint */
  margin-bottom: 40px;
  border-radius: 8px; /* Smooth rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.2s ease-in-out;
}


.profileEditSubheadingButtonContainer {
  margin-left: auto; /* Pushes button to the right */
}

.profileEditSmallButton {
  font-size: 12px;
  padding: 6px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.profileEditSmallButton:hover {
  background-color: #0056b3;
}



/* Error State */
.submitMessage.error {
  color: red;
}

.passwordChangeRow {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between elements */
}

.passwordInputContainer {
  position: relative;
  flex: 1;
  min-width: 180px;
}

.passwordToggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #666;
}

.passwordToggle:hover {
  color: #333;
}

.helperText {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}

.passwordWarning {
  font-size: 14px;
  color: red;
  font-weight: bold;
  margin-top: 5px;
}

.profileEditButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Shared styling for images & floorplans */
.propertyMediaSection {
  margin-top: 20px;
  padding: 15px;
  background: #f9f9f9;
  border-radius: 8px;
  border: 1px solid rgba(17, 43, 26, 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.propertyMediaSection h3 {
  margin-bottom: 10px;
}

/* Shared grid for images and floorplans */
.imageGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
}

.imageContainer {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.imageContainer:hover {
  transform: scale(1.05);
}

.propertyImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

/* 🔹 DELETE BUTTON (Red "✖") */
.deleteButton {
  position: absolute;
  top: 5px;
  right: 5px;
  background: red;
  color: white;
  border: none;
  font-size: 14px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
}

.deleteButton:hover {
  opacity: 1;
  background: darkred;
}