/* [PreQuotedWorkForm.module.css START] */

/****************************************
 * FORM + CALENDAR CONTAINER
 ****************************************/
 .preQuotedWorkContainer {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Left: Form | Right: Calendar */
  gap: 20px;
  align-items: start;
  /* background-color: red; */
}

.preQuotedReturnToDashboardRow {
  width: 100%;
  /* background-color: purple; */
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.preQuotedConfirmWorksRow {
  width: 100%;
   /* background-color: yellow; */
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: flex-end;
  margin: 20px 0;
}

.photoUploadSection {
  display: flex;
  background-color: #8d9f85;
  width: 100%;
  padding: 10px;
}

.photoUploadSection h4 {
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.photoUploadSection input[type="file"] {
  display: none !important;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
}

.hiddenFileInput {
  display: none !important;
}

.uploadButton {
  background-color: var(--mmg-button-blue-colour);
  color: white;
  font-size: 14px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.uploadButton:hover {
  background-color: #5a82c7; /* Muted blue */
}


.photoUploadInputRow {
  display: flex; /* Ensures it's visible */
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
}

.uploadedPhotoContainer {
  position: relative;
  display: inline-block;
}

.uploadedPhotos {
  padding: 10px;  
  background-color: rgba(17, 43, 26, 0.1);
}

.uploadedPhoto {
  width: 80px; /* Thumbnail size */
  height: 80px;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  padding-right: 5px;
}

.uploadedPhoto:hover {
  transform: scale(1.05);
  border-color: #5a82c7;
}

.deletePhotoButton {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.deletePhotoButton:hover {
  background-color: darkred;
}

.cancelBtn {
  background-color: var(--mmg-button-blue-colour);
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;  
}

.confirmBtn {
  background-color: var(--mmg-button-green-colour);
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;  
}

/****************************************
 * FORM STYLING
 ****************************************/
.preQuotedWorkForm {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/****************************************
 * FORM HEADINGS & TITLES
 ****************************************/
.preQuotedWorkForm h3 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

/* Boxed Titles for Labels (Net Price, VAT, etc.) */
.priceLabel {
  background-color: var(--cmh-title-soft-background-color);
  color: white;
  padding: 5px 15px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-radius: 4px;
  display: inline-block;
  width: 200px;
  margin-bottom: 5px;
}

/****************************************
 * FORM GRID LAYOUT
 ****************************************/
.pqwDetails {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 15px;
  align-items: center;
}

.pqwDetails label {
  display: block;
  font-weight: bold;
  font-size: 14px;
  color: #333;
  background: #b0b7ad;
  padding: 8px;
  border-radius: 6px;
  text-align: left;
}

/* Input & Textarea Styling */
.pqwDetails input,
.pqwDetails textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  background-color: #f3f3f3;
  color: #555;
  box-sizing: border-box;
}

.pqwDetails textarea {
  resize: none;
  min-height: 60px;
}

/****************************************
 * CALENDAR STYLING (Right Side)
 ****************************************/
.calendarContainer {
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.preQuotedWorkContainer {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Left: Form | Right: Calendar */
  gap: 20px;
  align-items: start;
}


.jobDetailRow {
  display: flex;
  flex-wrap: wrap; /* Ensures responsiveness */
  gap: 10px; /* Adds spacing between inputs */
  align-items: center; /* Aligns inputs properly */
  margin-bottom: 10px;
}

.jobDetailRow input {
  font-weight: bold;
  flex: 1; /* Allows inputs to grow within the row */
  min-width: 150px; /* Prevents inputs from shrinking too much */
  max-width: 250px; /* Stops inputs from getting too wide */
  box-sizing: border-box; /* Ensures consistent sizing */
}



/* [PreQuotedWorkForm.module.css END] */
