.costBreakdownTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.costBreakdownTable th {
  background-color: #e0e0e0; /* Neutral grey */
  padding: 8px;
  text-align: left;
  border: 1px solid #ccc;
  font-weight: bold;
}

.costBreakdownTable td {
  padding: 8px;
  border: 1px solid #ccc;
  text-align: left;
}

.costBreakdownTable th:nth-child(3), /* Net £ */
.costBreakdownTable td:nth-child(3),
.costBreakdownTable th:nth-child(4), /* VAT £ */
.costBreakdownTable td:nth-child(4) {
  width: 8%;
}

.costBreakdownTable th:nth-child(2), /* Description */
.costBreakdownTable td:nth-child(2) {
  width: auto; /* Allow description to take more space */
}

.costBreakdownTable th:last-child,
.costBreakdownTable td:last-child {
  width: 50px; /* Adjust as needed */
  text-align: center; /* Keeps the button centered */
  min-width: 80px; /* Ensures it doesn’t shrink too much */
}


/****************************************
 * ADD ROW BUTTON Styling
 ****************************************/

 .addRowBtn {
  width: 100%;
  background-color: #dce6f7; /* Muted blue */
  border: 1px solid #5a82c7; /* Soft blue border */
  color: #2c518a; /* Darker blue text */
  font-weight: bold;
  font-size: 2em;
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.addRowBtn:hover {
  background-color: #5a82c7; /* Stronger blue */
  color: white;
}

.addRowBtn:focus {
  outline: none;
  box-shadow: 0 0 3px rgba(90, 130, 199, 0.6);
}

.costBreakDownDescInput {
  width: 100%;
}

.costBreakDownSelect {
  padding: 8px 12px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-width: 200px;
  box-sizing: border-box;
  background-color: #fff;  
}

.costBreakDownSelectVAT {
  padding: 8px 12px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #fff;  
}