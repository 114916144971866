/* [Comments.module.css START] */

/****************************************
  * MAIN CONTAINER
****************************************/
.commentsContainer {
  top: 200px;
  max-width: 100%;
  height: calc(73vh - var(--header-height));
  display: flex;
  background-color: red;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 2px 5px rgba(0, 0, 0, 0.6);
  overflow-y: auto;
}

.commentsContainerContractor {
  height: 100%;
  max-width: 100%;
  display: flex;
  background-color: blue; /* just for visual debugging */
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5), 0px 2px 5px rgba(0, 0, 0, 0.6);
  overflow: hidden;
  position: relative;
}


/****************************************
  * CHAT SIDEBAR (USER LIST)
****************************************/
.chatSidebar {
  width: 350px;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.chatSidebarContractor {
  width: 200px;
  background: #fff;
  display: flex;
  flex-direction: column;
  /* flex: 1;  */
}


.chatUserList {
  flex: 1;
  overflow-y: auto;
}

.chatUserCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  background-color: transparent;
  border: 2px solid transparent;
}

.chatUserCard.selected {
  border: 2px solid green;
  background-color: rgba(17, 43, 26, 0.1);
}

.role {
  font-weight:lighter;
  font-style: italic;
  opacity: 0.7;
  font-size: 0.8em;
}


/****************************************
  * MAIN CHAT AREA
****************************************/
.chatMain {
  display: flex;
  position: relative;
  flex: 1;
  height: 100%;
  flex-direction: column;
  background: #e5ddd5;
}

.chatMainContractor {
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #e5ddd5;
}


.chatMessages {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  margin-bottom: 40px;
}

.chatMessagesContractor {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  /* flex: 1; */
  overflow-y: auto;
  /* padding: 20px; */
  /* margin-bottom: 40px; */
}

.chatBubbleContractor {
  background: #fff;
  border-radius: 10px;
  /* padding: 12px;
  max-width: 80%; */
  position: relative;
}

/****************************************
  * MESSAGE BOXES
****************************************/
.chatMessage {
  display: flex;
  margin: 5px 0;
  max-width: 80%;
}

/* Sent Messages (User's Own) */
.chatMessageSent {
  align-self: flex-end;
  text-align: right;
}

.chatMessageSent p {
  background: #eace9c;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 10px;
  text-align: left;
  position: relative;
}

/* Received Messages (Other Users) */
.chatMessageReceived {
  align-self: flex-start;
}

.chatMessageReceived p {
  background: #fff;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 10px;
  text-align: left;
  position: relative;
}

/****************************************
  * MESSAGE BUBBLE POINTERS
****************************************/
/* Outgoing message (User's own) */
.chatMessageSent p::before {
  content: '';
  position: absolute;
  top: 0;
  right: -12px;
  width: 20px;
  height: 20px;
  background: linear-gradient(135deg, #eace9c 50%, transparent 50%);
}

/* Incoming message */
.chatMessageReceived p::before {
  content: '';
  position: absolute;
  top: 0;
  left: -12px;
  width: 20px;
  height: 20px;
  background: linear-gradient(225deg, #fff 50%, transparent 50%);
}

/****************************************
  * INPUT BOX (CHAT TYPING AREA)
****************************************/
.chatInputContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.chatInputContainerContractor {
  flex-shrink: 0;
  padding: 6px 10px; /* tighten vertical space */
  border-top: 1px solid #ccc;
  /* position: absolute; */
  bottom: 0;
  left: 0;
  width: 100%;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.chatInputBox {
  width: 100%; 
  margin-right: 15px;
  min-height: 40px;
  max-height: 200px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1em;
  box-sizing: border-box; 
  resize: none; 
  overflow-y: hidden;
}

.chatInputBoxContractor {
  background-color: #fff;
  width: 100%; /* reduce from 80% */
  margin-right: 10px;
  min-height: 30px;
  max-height: 30px;
  padding: 6px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1em;
  box-sizing: border-box;
  resize: none;
  overflow-y: hidden;
}

.chatClosedBanner {
  background: #fff3cd;
  color: #856404;
  padding: 10px;
  font-size: 0.9rem;
  text-align: center;
  border-top: 1px solid #eee;
}

/****************************************
  * SEND BUTTON
****************************************/
.chatSendButton {
  padding: 10px 20px;
  background-color: var(--mmg-button-blue-colour);
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.chatSendButton:hover {
  background-color: var(--mmg-button-blue-hover-colour);
}

/****************************************
  * INFO PANEL (BOTTOM OF SIDEBAR)
****************************************/
.chatInfoPanel {
  padding: 10px;
  font-size: 0.9em;
  color: #555;
  text-align: center;
  border-top: 1px solid #eee;
  background: #f9f9f9;
}

.chatInfoPanel p {
  font-weight: bold;
  font-size: 1.2em;
}
.chatInfoPanel span {
  margin-top: 30px;
  color: green;
  font-weight: bold;
  font-size: 1em;
}


.commentsSideNavHeader{
  background-color: rgba(17, 43, 26, 0.1);
}

/****************************************
  * TIMESTAMP IN MESSAGE BUBBLES
****************************************/
.chatMessageTimestamp {
  font-size: 0.75em;
  color: #3b3a3a;
  float: right;
  margin-top: 10px;
}

.chatMessageTimestampContractor {
  display: block;
  text-align: right;
  font-size: 0.75em;
  color: #3b3a3a;
  margin-top: 8px;
}

/****************************************
  * FILE ATTACHMENTS
****************************************/

.chatAttachButton {
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  border: none;
  background: #ccc;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
}

.chatAttachButton:hover {
  background: #bbb;
}

.chatAttachments {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 5px;
}

.chatThumbnail {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  object-fit: cover;
  cursor: pointer;
}

.chatThumbnail:hover {
  opacity: 0.8;
}

.chatAttachments a {
  display: block;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.chatAttachments a:hover {
  text-decoration: underline;
}

.hiddenFileInput {
  display: none;
}

/****************************************
  * FILE PREVIEWS
****************************************/

.attachmentPreviewContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.imagePreview {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  object-fit: cover;
  cursor: pointer;
}

.imagePreview:hover {
  opacity: 0.8;
}

.pdfPreview {
  display: block;
  background: #f3f3f3;
  color: #007bff;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}

/****************************************
  * DELETE PREVIEWS
****************************************/

.chatThumbnailContainer {
  position: relative;
  display: inline-block;
  margin-right: 8px;
}

.chatThumbnail {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  object-fit: cover;
}

.deleteAttachment {
  position: absolute;
  top: -5px;
  right: -5px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteAttachment:hover {
  background: darkred;
}

.chatSendButton:disabled {
  background-color: #ccc; 
  cursor: not-allowed;
  opacity: 0.6;
}



.chatUserCardContractor {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  background-color: transparent;
  border: 2px solid transparent;
}

.chatUserCardContractor h4 {
  margin: 0;
  font-size: 0.95rem;
  line-height: 1.2;
}

.chatUserCardContractor .role {
  font-weight: lighter;
  font-style: italic;
  opacity: 0.7;
  font-size: 0.75rem;
  line-height: 1.1;
  margin: 2px 0 0 0;
}

.chatUserCardEven {
  background-color: #f8f8f8;
}

.chatUserCardOdd {
  background-color: #ffffff;
}


/* [Comments.module.css END] */
