/* [AppointmentForm.module.css START] */

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.calendarContainer {
  flex: 1; /* Takes up half the space */
  max-width: 50%;
}

.detailsContainer {
  flex: 1;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Multi-visit toggle */
.multiVisitToggle {
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.multiVisitToggle input {
  transform: scale(1.2);
}

/* Selected Dates */
.selectedDatesList {
  list-style: none;
  padding: 0;
  width: 100%;
}

.selectedDateItem {
  background-color: #f8f9fa;
  padding: 8px 12px;
  margin: 6px 0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.descriptionInput {
  flex: 1;
  margin-left: 10px;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.selectedDatesList li {
  background-color: #f1f1f1;
  padding: 5px;
  margin: 5px 0;
  border-radius: 5px;
}

.negativeButton {
  background-color: red;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.2s ease-in-out;
}

.negativeButton:hover {
  background-color: darkred;
}

.confirmButton {
  background-color: green;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.2s ease-in-out;
}

.confirmButton:hover {
  background-color: darkgreen;
}

/* Buttons */
.submitButton {
  background-color: #007bff;
  color: white;
  padding: 12px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s ease-in-out;
  border-radius: 6px;
  /* width: 100%; */
}

.submitButton:hover {
  background-color: #0056b3;
}

.disabledButton {
  opacity: 0.5;
  cursor: not-allowed;
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  display: inline-block;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Success Message */
.successMessage {
  font-size: 14px;
  color: green;
  font-weight: bold;
  text-align: center;
}

.helperText {
  font-size: 12px;
  color: #666;
  /* margin-top: 4px; */
  /*padding-left: 6px; /* Small indent without excessive spacing */
  display: block; /* Ensures it appears below the checkbox */
  /* max-width: 300px;  */
  line-height: 1.4; /* Improves readability */
}

.multiVisitWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns to left without unnecessary space */
  gap: 4px;
}

.calendarHelperText {
  font-size: 18px;
  font-weight: bold;
  color: #666;
  text-align: center;
  margin-top: 8px;
}

.confirmedGlow {
  border: 2px solid #4CAF50; /* Softer green */
  box-shadow: 0px 0px 4px rgba(76, 175, 80, 0.5); /* Reduced intensity */
  transition: box-shadow 0.2s ease-in-out;
}


/****************************************
 * JOB DETAILS SECTION (APPOINTMENT FORM)
 ****************************************/
 .jobDetails {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 12px;
  margin-top: 10px;
  width: 100%;
}

.jobDetailRow {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal columns */
  gap: 15px;
  align-items: center;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
  background-color: #f3f3f3;
  color: #555;
}

/* [AppointmentForm.module.css END] */
